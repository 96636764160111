.testiCards{
    width: 100%;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    gap: 20px;
}
.testiCard{
    text-align: center;
    align-items: center;
    width: 32%;
    height: 100%;
    border: 1px solid #e4ebf3;
    padding: 32px 16px 20px;
    transition: .3s ease all;
    margin-bottom: 20px;
}
.testiCard:hover{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.testiCard p{
    font-style: italic;
}
.testiImage{
    width: 180px;
    height: 200px;
    align-items: center;
    padding: 24px 16px;
    display: flex;
}
.img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.texts h3{
    margin-top: 0;
}
.explanation{
    color: #6e6b6b;
    padding: 5px 0 10px;
}
.recommendation2{
    padding: 68px 0;
}
.exp2{
    padding-top: 20px;
}
.recommendation3{
    padding-bottom: 84px;
}
@media(max-width:1440px){
    .recommendation3{
        padding: 14px 0;
    }
}
@media(max-width:1300px){
    .recommendation3{
        padding: 28px 0;
    }
    .recommendation2{
        padding: 64px 0;
    }
}

@media(max-width:1024px){
    .testiCard{
        width: 48%;
    }
    .recommendation2{
        padding: 48px 0;
    }
}

@media(max-width:768px){
    .testiCard{
        width: 100%;
    }
}
