.header {
    padding: 10px 0;
    border-bottom: 1px solid #aca9a9;
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 1000; 
    top: 0; 
}

.headerContent {
    align-items: center;
    gap: 150px;
}
.logo{
    width: 15%;
}

.logoLink {
    cursor: pointer;
    text-decoration: none; 
}
.logoImg{
    width: 100%;
    height: 100%;
}

.navList {
    gap: 50px;
}

.listItem {
    cursor: pointer;
}

.navLink:hover {
    color: rgb(27, 109, 141);
}

.navLink {
    font-size: 20px;
    color: #000;
    transition: .3s ease all;
    text-decoration: none; 
}

.menuIcon {
    display: none;
    flex-direction: column;
    cursor: pointer;
    z-index: 1500; 
}

.bar {
    width: 25px;
    height: 3px;
    background-color: #000;
    margin: 3px 0;
}
@media (max-width:1100px){
    .logo{
        width: 30%;
    }
}

@media (max-width: 1024px) {
    .headerContent{
        justify-content: space-between;
    }
    .menuIcon {
        display: flex;
    }

    .nav {
        display: none;
        position: absolute;
        top: 50px; 
        left: 0;
        width: 100%;
        background-color: #fff;
        padding: 20px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        z-index: 1200;
    }
    
    .nav.open {
        display: block;
    }

    .navList {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}

@media (max-width:540px){
    .logo{
        width: 40%;
    }
}