.projectContent{
    width: 100%;
}
.project{
    padding: 20px 0;
    align-items: center;
}
.projectImage{
    width: 30%;
    height: 100%;
}

.projectPhoto{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.projectText{
    width: 70%;
    font-size: 24px;
}
.projectText p{
    padding: 10px 0 0 20px;
}
.projectText p b{
    font-size: 24px;
}
.projectText ul{
    padding-left: 50px;
}

.projectText ul li{
    list-style-type:disc;
}
.projectOne{
    padding-bottom: 100px;
    border-bottom: 1px solid #ccc;
}
.projectTwo{
    padding-top: 100px;
    flex-direction: row-reverse;
}
.projectTwo .projectText p{
    padding: 20px 10px 0 0;
}


@media (max-width:1130px){
    .project{
        flex-direction: column;
    }
    .projectImage{
        width: 50%;
    }
    .projectText{
        width: 100%;
    }
    .projectOne p{
        padding: 20px 0 0;
    }
}
@media (max-width:768px){
    .projectText p b{
        font-size: 20px;
    }
    .projectTwo{
        padding-top: 50px;
    }
    .projectImage{
        width: 70%;
    }
    .projectText{
        width: 100%;
        font-size: 20px;
    }
}
@media (max-width:500px){
    .projectImage{
        width: 100%;
    }

}


