
.experience{
    padding: 4rem 0;
    background-color: rgb(236, 236, 236);
}
.expContext{
    width: 100%;
    align-items: center;
    gap: 30px;
}
.introText{
    padding: 50px 0;
    text-align: center;
    font-size: 32px;
}
.introImageContainer{
    align-items: center;
    justify-content: center;
    width: 100%;
}
.introImageDiv{
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.introImage{
    width: 50%;
    object-fit: cover;
}
.expName{
    padding-top: 150px;
}
.expText{
    font-family: 'Eb Garamond';
    font-weight: 400;
    width: 100%;
    line-height: 1.6;
    font-size: 24px;
    padding-bottom: 50px;
}
.expText b{
    font-size: 32px;
}

.expText ul li{
    margin-left: 40px;
    list-style-type:disc;
}
.expName{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.expName h3{
    font-size: 36px;
    padding-bottom: 20px;
    width: 50%;
}
.expName p{
    padding: 20px 0 30px;
    font-size: 20px;
    line-height: 1.5;
}
.expImage{
    width: 30%;
    height: 50%;
}
.expImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.logos{
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.logosHeadText{
    font-size: 28px;
    text-align: center;
    padding: 80px 0;
    width: 70%;
}
.expLogos{
    width: 100%;
    height: 100%;
    gap: 50px;
    display: flex;
    flex-wrap: wrap;
}
.expLogo{
    width: 30%;
    height: 100%;
    text-align: center;
    align-items: center;
}
.logoImage{
    width: 250px;
    height: 250px;
}
.expLogoImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.logoTitle p{
    padding: 10px 0;
}
.logoTitle p:first-of-type{
    font-weight: bold;
}
.logoTitle p:nth-of-type(2){
    font-weight: bold;
    color: #878686;
}
@media (max-width:1300px){ 
    .expContext{
        flex-direction: column-reverse;
    }
    .expImage{
        width: 50%;
    }
    .introText{
        font-size: 36px;
    }
    .expText{
        width: 100%;
    }
    .expLogo{
        width: 45%;
        height: 100%;
    }
}
@media (max-width:768px){
    .expText p{
        text-align: justify;
    }
    .expImage{
        width: 70%;
    }
    .expText{
        font-size: 20px;
    }
    .introText{
        padding: 25px 0 50px;
        font-size: 32px;
    }
    .logosHeadText{
        font-size: 28px;
    }
    .expLogo{
        width: 100%;
        height: 100%;
    }
    .expName h3{
        width: 100%;
    }
}

@media (max-width:500px){
    .expImage{
        width: 100%;
    }
    .expText{
        font-size: 20px;
    }
    .introText{
        font-size: 24px;
    }
    .logosHeadText{
        font-size: 24px;
    }
    
}