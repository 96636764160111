.footer{
    padding: 30px 0 50px;
    background-color: rgb(197, 197, 248);
    color: #000000;
}
.topPart{
    align-items: center;
}
.sectionTxts{
    text-align: center;
}
.sectionTxts h2{
    padding-bottom: 10px;
}
.iconList{
    padding: 30px 0;
}

.iconLink{
    color: #000000;
    font-size: 20px;
    background-color: none;
    transition: .3s ease all;
}
.formDiv{
    width: 100%;
}
.form{
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 100%;
}
input{
    padding: 10px 0;
    width: 33%;
    color: #000000;
    border: none;
    outline: none;
    border-bottom: 1px solid #9c9c9c;
    background-color: rgb(197, 197, 248);
}

.btn{
    border: none;
    padding: 10px 20px;
    transition: .3s ease all;
}
.btn:hover{
    box-shadow: 0 8px 10px 0 rgb(186, 186, 249), 0 10px 20px 0 rgb(161, 161, 242);
}
@media(max-width:550px){
    .form{
        flex-direction: column;
    }
    input{
        width: 100%;
    }
}