.blog{
    padding: 40px 0;
    width: 100%;
    border-top: 1px solid #aca9a9;
    background-color: rgb(236, 236, 236);
}
.cards{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 30px;
}
.card{
    background-color: #ebe7e7;
}
.cardLink{
    /* display: inline-block; */
    color: #000;
    width: 31.5%;
    /* gap: 10px; */
    border: 1px solid #d6d5d5;
    transition: .3s ease all;
}
.image{
    width: 100%;
    height: 100%;
}
.cardLink:hover{
    box-shadow: 0 4px 8px 0 rgba(8, 8, 8, 0.2), 0 6px 20px 0 rgba(10, 9, 9, 0.2);
}
.blogImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.cardContent{
    font-size: 18px;
    padding: 10px;
}
.cardContent p{
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

@media(max-width:1330px){
    .cardLink{
        width: 48%;
    }
}
@media(max-width:920px){
    .cardLink{
        width: 100%;
    }
}

