@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');
*{
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}
.container{
  width: 82%;
  margin: 0 auto;
}

.flex{
  display: flex;
}
.column{
  display: flex;
  flex-direction: column;
}
.section{
  padding: 50px 0;
  width: 100%;
  border-top: 1px solid #aca9a9;
}
.content{
  gap: 20px;
}

.name h3{
  font-size: 48px;
  padding-bottom: 80px;
  text-align: center;
}
@media (max-width:500px){
  .name h3{
    font-size: 48px;
  }
}
@media (max-width:330px){
  .name h3{
    font-size: 36px;
  }
}
