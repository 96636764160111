@import url('https://fonts.googleapis.com/css2?family=Truculenta:opsz,wght@12..72,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&display=swap');

.hero {
    padding: 13rem 0;
    background-color: rgb(197, 197, 248);
    font-family: Alegreya, sans-serif;
}

.context {
    width: 100%;
    gap: 50px;
    align-items: center;
    justify-content: center;
}

.heroImg {
    width: 40%;
    height: 100%;
}

.heroImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

b{
    font-size: 52px;
}
@media (max-width:400px){
    b{
        font-size: 28px;
    }
    .aboutText{
        font-size: 20px;
    }
}

.text {
    width: 70%;
}
.aboutText{
    font-size: 44px;
    font-style: italic;
}
@media (max-width:1256px){
    b{
        font-size: 40px;
    }
    .aboutText{
        font-size: 36px;
    }
}

@media (max-width:1024px) {
    .context {
        gap: 30px;
        flex-direction: column;
        text-align: center;
    }

    .heroImg {
        width: 50%;
    }

    .text {
        width: 100%;
    }
}
@media (max-width:768px){
    .heroImg{
        width: 100%;
    }
}